<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Создать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Формальная фотография" prop="primaryImage">
        <Uploader
          :limit="1"
          :files="
            form.primaryImage && form.primaryImage.original
              ? [form.primaryImage]
              : []
          "
          @upload="uploadPrimaryImage"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Неформальная фотография" prop="secondaryImage">
        <Uploader
          :limit="1"
          :files="
            form.secondaryImage && form.secondaryImage.original
              ? [form.secondaryImage]
              : []
          "
          @upload="uploadSecondaryImage"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Должность" prop="jobTitle">
        <el-input v-model="form.jobTitle"></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <el-input v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import regexp from '@/helpers/regexp.js'

export default {
  components: {
    Uploader,
  },
  data() {
    return {
      form: {
        primaryImage: {
          caption: '',
          isActive: true,
          order_field: 0,
          original: '',
        },
        secondaryImage: {
          caption: '',
          isActive: true,
          order_field: 0,
          original: '',
        },
        name: '',
        jobTitle: '',
        description: '',
        email: '',
        phone: '',
        orderField: 0,
      },
      rules: {
        primaryImage: [
          {
            type: 'object',
            required: true,
            trigger: 'change',
            validator: this.validateImage,
          },
        ],
        secondaryImage: [
          {
            type: 'object',
            required: true,
            trigger: 'change',
            validator: this.validateImage,
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        jobTitle: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.email,
            message: 'Некорректный email',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.phone,
            message: 'Некорректный номер телефона',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    uploadPrimaryImage(images) {
      this.form.primaryImage = images[0]
    },
    uploadSecondaryImage(images) {
      this.form.secondaryImage = images[0]
    },
    validateImage(_, value, callback) {
      value && value.original
        ? callback()
        : callback(new Error('Пожалуйста, загрузите картинку'))
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const result =
            await delivery.ContentServiceCore.TeamMembersActions.create(
              this.form,
            )
          if (!result.error) {
            this.$message({
              message: 'Сотрудник успешно создан',
              type: 'success',
            })
            this.$router.push('/addwine/content/team-members')
          }
          loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
  }
  .form {
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
